import Vue from "vue";
import Vuex from "vuex";
import account from "./account";
import hydrate from "./hydrate";
import platform from "./platform";
import root from "./root";
import pref from "./pref";
import subscription from "./subscription";

Vue.use(Vuex);

const store = new Vuex.Store({
  ...root,
  modules: {
    hydrate,
    account,
    platform,
    pref,
    subscription
  }
});

// Initial state
const initialState = JSON.parse(JSON.stringify(store.state));

//Resets the store when user logs out
const resetStore = () => {
  store.replaceState(JSON.parse(JSON.stringify(initialState)));
  localStorage.removeItem("vuex"); //Removes VueX Persisted Data
};

export default store;
export { resetStore };
