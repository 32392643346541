import axios from "@/plugins/axios";

const login = ({ body }) => {
  return axios.post("V1/login", body).then(res => {
    if (res.data.data.msg == "error") throw res.data.data;
    return res.data.data.token;
  });
};

const signup = ({ body }) => {
  return axios.post("V1/signup", body).then(res => {
    if (res.data.data.msg === "success") {
      return true;
    } else {
      throw res.data.data || "Unknown Error!";
    }
  });
};

const passwordResetOtp = ({ body }) => {
  return axios.post(`/V1/password/email`, body).then(res => {
    if (res.data.data.msg === "success") {
      return true;
    } else {
      throw res.data.data || "Unknown Error!";
    }
  });
};

const passwordReset = ({ body }) => {
  return axios.post(`/V1/password/reset`, body).then(res => {
    if (res.data.data.msg === "success") {
      return true;
    } else {
      throw res.data.data || "Unknown Error!";
    }
  });
};

const resetVerificationEmail = ({ body }) => {
  return axios.post(`/V1/resend-verification-email`, body).then(res => {
    if (res.data.data.msg === "success") {
      return true;
    } else {
      throw res.data.data || "Unknown Error!";
    }
  });
};
export default {
  login,
  signup,
  passwordResetOtp,
  passwordReset,
  resetVerificationEmail
};
