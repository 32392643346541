<template>
  <component :is="tag" class="product-amount">
    <span v-if="isValueNegetive" class="mr-1">-</span>
    <span v-if="symbol" class="mr-1">{{ currency }}</span>
    <span>{{ localValue }}</span>
  </component>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    value: Number,
    tag: {
      type: String,
      default: "div"
    },
    symbol: {
      type: Boolean,
      default: true
    },
    isNegetive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["store", "currency", "currencyDecimals"]),

    isInt() {
      return this.value % 1 == 0;
    },

    isValueNegetive() {
      return this.isNegetive || this.value < 0;
    },

    localValue() {
      if (!this.value) return 0;
      return new Intl.NumberFormat("en", {
        maximumFractionDigits: this.currencyDecimals,
        minimumFractionDigits: this.isInt ? 0 : this.currencyDecimals
      }).format(Math.abs(this.value) || 0);
    }
  }
};
</script>

<style lang="scss">
.product-amount {
  white-space: nowrap;
  flex-wrap: nowrap;
  display: inline-flex;
  align-items: center;
  p {
    flex: 0 0 auto;
  }
}
</style>
