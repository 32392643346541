export default [
  {
    path: "",
    redirect: "campaigns",
  },
  {
    path: "users",
    name: "users",
    component: require("@/views/users/List").default,
    meta: {
      label: "Users",
      breadcrumbs: ["users"],
      icon: "AccountGroup"
    },
  },
  {
    path: "users/:id",
    name: "user",
    component: require("@/views/users/user/Index").default,
    redirect: { name: "user-profile" },
    props: true,
    meta: {
      label: "User",
      breadcrumbs: ["users", "user"],
    },
    children: [
      {
        path: "",
        name: "user-profile",
        component: require("@/views/users/user/View").default,
        props: true,
        meta: {
          label: "Profile",
          breadcrumbs: ["user", "user-profile"],
        },
      },
      {
        path: "campaign",
        name: "user-campaign",
        component: require("@/views/users/user/Campaign").default,
        props: true,
        meta: {
          label: "Campaign",
          breadcrumbs: ["user", "user-campaign"],
        },
      },
      {
        path: "subscription",
        name: "user-subscription",
        component: require("@/views/users/user/Subscription").default,
        props: true,
        meta: {
          label: "Subscription",
          breadcrumbs: ["user", "user-subscription"],
        },
      },
    ],
  },
  {
    path: "campaigns",
    name: "campaigns",
    component: require("@/views/campaigns/List").default,
    meta: {
      label: "campaigns",
      breadcrumbs: ["campaigns"],
      icon: "Briefcase"
    },
  },
  {
    path: "plans",
    name: "plans",
    component: require("@/views/subscription/List").default,
    meta: {
      label: "Plans",
      breadcrumbs: ["plans"],
      icon: "CurrencyUsdCircle"
    },
  },
  {
    path: "setting",
    name: "setting",
    component: require("@/views/setting/Index").default,
    meta: {
      label: "Settings",
      breadcrumbs: ["setting"],
      icon: "vmdi-cog"
    },
  },
];
