import Vue from "vue";
import draggable from "vuedraggable";
import filesize from "filesize";
import { graph, graphSparta, graphPublic } from "@/plugins/graphql";

Vue.component("Error", require("@/components/Error").default);
// Vue.component("ItemForm", require("@/components/ItemForm").default);
Vue.component("Iconify", require("@/components/Iconify").default);
Vue.component("Draggable", draggable);

Vue.filter("filesize", (value) => {
  if (!value) return "";
  return filesize(value);
});

Vue.filter("number", (value) => {
  if (!value) value = 0;
  return new Intl.NumberFormat(
    window.navigator.userLanguage || window.navigator.language,
    {
      minimumFractionDigits: 2,
    }
  ).format(value.toFixed(2));
});

Vue.prototype.$graph = (query, variables) => graph(query)(variables);
Vue.prototype.$graphSparta = (query, variables) =>
  graphSparta(query)(variables);
Vue.prototype.$graphPublic = (query, variables) =>
  graphPublic(query)(variables);
