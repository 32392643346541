export default {
  dashboard: require("@icon/ViewDashboard").default,
  Briefcase: require("@icon/Briefcase").default,
  AccountGroup: require("@icon/AccountGroup").default,
  CurrencyUsdCircle: require("@icon/CurrencyUsdCircle").default,
  LogoutVariant: require("@icon/LogoutVariant").default,
  Email: require("@icon/Email").default,
  HelpCircle: require("@icon/HelpCircle").default,
  LockReset: require("@icon/LockReset").default,
  Lock: require("@icon/Lock").default,
  LoginVariant: require("@icon/LoginVariant").default,
  AccountCircle: require("@icon/AccountCircle").default,
  Close: require("@icon/Close").default,
  OpenInNew: require("@icon/OpenInNew").default,
  FilterRemove: require("@icon/FilterRemove").default,
  CheckBold: require("@icon/CheckBold").default,
  CardAccountDetailsOutline: require("@icon/CardAccountDetailsOutline").default,
  "vmdi-email": require("@icon/Email").default,
  "vmdi-lock": require("@icon/Lock").default,
  "vmdi-cog": require("@icon/Cog").default,
  "vmdi-login-variant": require("@icon/LoginVariant").default,
  "vmdi-calendar-month": require("@icon/CalendarMonth").default,
};
