import graphql from "graphql.js";
import fragments from "@/graph/fragments";
import { version } from "../../package.json";
var graph, graphSparta;

export const init = (token) => {
  token = `Bearer ${token}`;
  graph = graphql(process.env.VUE_APP_GRAPHQL, {
    method: "POST", // POST by default.
    headers: {
      Authorization: token,
      "web-version": version,
    },
    fragments: fragments,
  });

  graphSparta = graphql(process.env.VUE_APP_GRAPHQL_SPARTA, {
    method: "POST", // POST by default.
    headers: {
      Authorization: token,
      "web-version": version,
    },
    fragments: fragments,
  });
};

const graphPublic = graphql(process.env.VUE_APP_GRAPHQL_OPEN, {
  method: "POST", // POST by default.
  headers: {
    "web-version": version,
  },
});

export { graph, graphSparta, graphPublic };
