<template>
  <div class="emoji-card" :class="classList">
    <div class="emoji-card__emoji text-emoji">{{ emoji }}</div>
    <p>
      <slot name="title">
        {{ title }}
      </slot>
    </p>
    <label>{{ desc }}</label>
  </div>
</template>

<script>
export default {
  props: {
    emoji: String,
    title: [String, Number],
    desc: String,
    size: {
      type: String
    },
    inline: Boolean
  },

  computed: {
    classList() {
      const classes = [];
      if (this.size) {
        classes.push(`emoji-card--${this.size}`);
      }
      if (this.inline) {
        classes.push(`emoji-card--inline`);
      }
      return classes;
    }
  }
};
</script>

<style lang="scss">
.emoji-card {
  padding: --space(4);
  background-color: --color("light");
  border-radius: --radius(4);
  p {
    font-size: --text("md");
    font-weight: bold;
    line-height: 1;
  }
  label {
    color: --color("grey");
  }
}
.emoji-card__emoji {
  font-size: --text("xl");
}

.emoji-card--sm {
  padding: --space(3);
  border-radius: --radius(3);
  p {
    font-size: --text("sm");
  }
  .emoji-card__emoji {
    font-size: --text("lg");
  }
}

.emoji-card--inline {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: --space(3);
  p {
    align-self: end;
  }
  .emoji-card__emoji {
    grid-row: 1/3;
  }
}
</style>
