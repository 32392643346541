import { SET_PLATFORM_USER } from "./mutation-types";

const state = {
  user: null
};

const getters = {
  user(state) {
    return state.user;
  }
};

const mutations = {
  [SET_PLATFORM_USER](state, data) {
    this._vm.$set(state, "user", data);
  }
};

const actions = {
  setUser({ commit }, data) {
    commit(SET_PLATFORM_USER, data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
