<template>
  <list-layout>
    <sp-list
      v-if="user.campaigns"
      :data="user.campaigns"
      :attrs="columns"
      :actions="['refresh']"
      :filters="filters"
    >
      <sp-list-table sticky-header class="pt-0" :reorder="true">
        <template #country="{item}">
          <p>{{ item.country && item.country.name }}</p>
        </template>
        <template #profilePicture="{item}">
          <s-media
            :value="item.profilePicture && item.profilePicture.url"
            width="40px"
            height="40px"
          />
        </template>
        <template #subdomain="{item}">
          <s-button
            :label="item.subdomain"
            color="info"
            :href="item.subdomainUrl"
            target="_blank"
            size="sm"
            v-if="item.subdomain"
          />
        </template>
        <template #users="{item}">
          <p>{{ item.users.length }}</p>
        </template>
        <template #replies="{item}">
          <p>{{ item.replies.length }}</p>
        </template>
        <template #funnels="{item}">
          <p>{{ item.funnels.length }}</p>
        </template>
        <template #contacts="{item}">
          <p>{{ item.contacts.length }}</p>
        </template>
      </sp-list-table>
    </sp-list>
  </list-layout>
</template>

<script>
import list from "@/mixins/list";

export default {
  props: {
    user: Object,
  },
  mixins: [list],
  data() {
    return {
      filters: {},
    };
  },
  computed: {
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "name" },
        { name: "profilePicture", fix: true },
        { name: "subdomain" },
        { name: "users" },
        { name: "replies" },
        { name: "funnels" },
        { name: "contacts" },
        { name: "language" },
        { name: "country" },
        // { name: "_drag", label: "", fix: true }
      ];
    },
  },
};
</script>
