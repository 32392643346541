import { SET_SUBSCRIPTION } from "./mutation-types";
import { cloneDeep } from "lodash-es";

const state = {
  paddleAffiliatorId: null,
  plan: null,
  quota: null,
  uuid: null,
  validity: null,
  isActive: false,
  features: null,
  paddleCancelUrl: null
};

const getters = {
  cancelUrl(state) {
    return state.paddleCancelUrl;
  },
  isActive(state) {
    return state.isActive;
  },
  canUseFeature: (state, getters) => feature => {
    return getters.isActive && state.features[feature] == true;
  },
  planName(state) {
    return state.plan?.name;
  },
  planId(state) {
    return state.plan?.id;
  },
  validity(state) {
    return state.validity;
  },
  price(state) {
    return state.plan?.price || 0;
  },
  uuid(state) {
    return state.uuid;
  },
  quota(state) {
    return state.quota;
  },
  provider(state) {
    return state.plan?.provider;
  },
  canChange(state, getters) {
    return getters.provider == "paddle" || getters.provider == "default";
  },
  quotaInPlan(state) {
    if (!state.features) return {};

    const {
      polls,
      quickReplies,
      quickRepliesDailyLimit,
      products,
      tags,
      storage
    } = state.features;

    return {
      polls,
      quickReplies,
      quickRepliesDailyLimit,
      products,
      tags,
      storage
    };
  }
};

const mutations = {
  [SET_SUBSCRIPTION](state, data) {
    const {
      paddleAffiliatorId,
      plan,
      quota,
      uuid,
      validity,
      isSubscriptionActive,
      paddleCancelUrl
    } = data;
    state.paddleAffiliatorId = paddleAffiliatorId;
    state.features = plan.feature;
    state.plan = plan;
    state.quota = quota;
    state.uuid = uuid;
    state.validity = validity;
    state.isActive = isSubscriptionActive;
    state.paddleCancelUrl = paddleCancelUrl;
  }
};

const actions = {
  set({ commit, dispatch }, data) {
    const dailyQuickReplyLeft = data.quota.quickRepliesDailyLimit;
    if (dailyQuickReplyLeft != null) {
      dispatch("setDailyQuickReplyLeft", dailyQuickReplyLeft, { root: true });
    }

    commit(SET_SUBSCRIPTION, data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
