import axios from "@/plugins/axios";
export default async ({ params }) => {
  return axios
    .get("V2/me", { params })
    .then(res => res.data)
    .then(res => {
      const { campaigns } = res;
      return {
        defaultCampaignId: campaigns?.[0].id
      };
    });
};
