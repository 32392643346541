<template>
  <div class="dashboard p-4 bg-grey-lightest h-100" v-if="userHasData">
    <h4 class="text-grey mb-1">Plan</h4>
    <div class="grid grid--4 g-3 mb-3">
      <emoji-card inline emoji="📦" :title="plan.name" desc="Plan" />
      <emoji-card inline emoji="💵" title="Price" desc="Price">
        <template #title>
          <amount :value="plan.price" />
        </template>
      </emoji-card>
      <emoji-card inline emoji="⏱️" :title="plan.duration" desc="Duration" />
    </div>
    <h4 class="text-grey mb-1">Quota</h4>
    <div class="grid grid--4 g-3">
      <emoji-card inline emoji="📈" :title="quota.polls" desc="Polls" />
      <emoji-card inline emoji="🛒" :title="quota.products" desc="products" />
      <emoji-card
        inline
        emoji="↩️"
        :title="quota.quickReplies"
        desc="Quick Replies"
      />
      <emoji-card
        inline
        emoji="⚠️"
        :title="quota.quickRepliesDailyLimit"
        desc="Quick Replies Daily Limit"
      />
      <emoji-card
        inline
        emoji="💽"
        :title="filesize(quota.storage)"
        desc="Storage"
      />
      <emoji-card inline emoji="🏷️" :title="quota.tags" desc="Tags" />
    </div>
  </div>
</template>

<script>
import filesize from "filesize";

export default {
  props: {
    user: Object
  },
  components: {
    EmojiCard: require("@/components/EmojiCard").default,
    Amount: require("@/components/Amount").default
  },
  data() {
    return {
      filesize
    };
  },
  computed: {
    userHasData() {
      return this.user.subscription;
    },
    quota() {
      return this.user.subscription?.quota;
    },
    plan() {
      return this.user.subscription?.plan;
    }
  }
};
</script>
