//byContext
//Accepts 2 args, 1st for browser and 2nd for extension
export const byContext = (browser, extension) => {
  if (process.env.VUE_APP_EXT == "true") {
    return extension();
  } else {
    return browser();
  }
};

export const injectScript = (path, runtime) => {
  return new Promise(resolve => {
    var s = document.createElement("script");
    if (runtime) {
      s.src = window.chrome.runtime.getURL(path);
    } else {
      s.src = path;
    }
    s.onload = function() {
      this.remove();
      resolve();
    };
    (document.head || document.documentElement).appendChild(s);
  });
};

export const toBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

// export const urlToBase64 = url => {
//   return new Promise(async (resolve, reject) => {
//     const res = await fetch(url)
//       .then(res => res.blob())
//       .catch(err => {
//         console.error(err);
//       });
//     const reader = new FileReader();
//     reader.onloadend = () => resolve(reader.result);
//     reader.onerror = reject;
//     reader.readAsDataURL(res);
//   });
// };
