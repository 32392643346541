import { SET_PREF } from "./mutation-types";

const state = {
  isAdminNavCollapsed: false
};

const getters = {};

const mutations = {
  [SET_PREF](state, [key, value]) {
    state[key] = value;
  }
};

const actions = {
  set({ commit }, data) {
    commit(SET_PREF, data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
