<template>
  <div class="h-100">
    <!-- <s-modal-container /> -->
    <s-notification-container width="280px" />
    <s-confirm />
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "@/scss/shilp.scss";
@import "shilp/scss/styles.scss";
@import "@/scss/style.scss";

html,
body {
  height: 100%;
}
.whatshash-web {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  height: 100%;
}
.whatshash-web--logged-in {
  grid-template-columns: min-content 1fr;
  .sign {
    grid-template-columns: 1fr;
  }
  .sign__left {
    display: none;
  }
}

.whatshash-web--mobile-app {
  grid-template-columns: 1fr;
  .web-nav {
    display: none;
  }
}
</style>
