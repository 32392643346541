import { byContext } from "@/utils/helpers";
import { graph, graphSparta } from "@/plugins/graphql";

export default (query, vars, isSparta = false) => {
  return new Promise((resolve, reject) => {
    byContext(
      () => {
        try {
          if (isSparta) {
            resolve(graphSparta(query)(vars));
          } else {
            resolve(graph(query)(vars));
          }
        } catch (err) {
          console.error("May be query not found!");
          console.error(err);
        }
      },
      () => {
        try {
          window.chrome.runtime.sendMessage(
            { data: [query, vars], action: "graph" },
            res => {
              if (res?.success === true) {
                resolve(res.data);
              } else {
                reject(res.data);
              }
            }
          );
        } catch (err) {
          console.error(err);
          const msg = `
🚨 WhatsHash Disconnected:\n
It generally happens when WhatsHash is updated to newer version or your internet connection is changed. Just refresh the page to reconnect. WhatsHash will not work until the page is refreshed.
\nDo you want to refresh now?
`;
          const doRefresh = confirm(msg);
          if (doRefresh) {
            window.location.reload();
          }
        }
      }
    );
  });
};
