import auth from "@/api/auth";
import hydrate from "@/api/hydrate";
import campaign from "@/api/campaign";

export default {
  LOGIN: auth.login,
  SIGNUP: auth.signup,
  PASSWORD_RESET_OTP: auth.passwordResetOtp,
  PASSWORD_RESET: auth.passwordReset,
  RESEND_VERIFICATION_EMAIL: auth.resetVerificationEmail,
  HYDRATE: hydrate,

  CAMPAIGN_LIST: campaign.list
};
