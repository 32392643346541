import request from "@/utils/request";
import { debounce, capitalize, cloneDeep } from "lodash-es";
// import reorder from "@/admin/graph/reorder";
// import graph from "@/utils/graph";

export default {
  data() {
    return {
      settings: {
        archived: false
      },
      defaultFilters: {}
    };
  },
  computed: {
    reorderColumn() {
      if (!this.filters?.archived) {
        return [{ name: "_drag", label: "", fix: true }];
      }
      return [];
    }
  },
  components: {
    ListLayout: require("@/components/ListLayout").default,
    ListFilters: require("@/components/ListFilters").default
    // ListFilterArchived: require("@admin/components/ListFilterArchived").default,
  },

  created() {
    this.defaultFilters = cloneDeep(this.filters);
  },

  methods: {
    updateBreadcrumb(key, value) {
      this.$root.$emit("shilp-breadcrumbs-label", key, capitalize(value));
    },

    open(route, id) {
      this.$router.push({
        name: route,
        params: {
          id: id
        }
      });
    },

    // async sort2(requestName, items, payload) {
    //   const ids = items.map(item => item.id);
    //   try {
    //     await graph(reorder[requestName], {
    //       ids,
    //       ...payload
    //     });
    //   } catch (err) {
    //     console.error(err);
    //     this.$notify({
    //       title: "Reordering failed!",
    //       message: "Please refresh & try again.",
    //       type: "danger"
    //     });
    //   }
    // },

    async sort(requestName, { body, params }) {
      const newOrder = body.map(item => {
        return { id: item.id };
      });
      try {
        await request(requestName, { body: { data: newOrder }, params });
      } catch (err) {
        // console.error(err);
        this.$notify({
          message: "Sorting failed! Please refresh & try again.",
          type: "danger"
        });
      }
    },

    resetFilters() {
      this.$set(this, "filters", cloneDeep(this.defaultFilters));
    },

    applyFilter: debounce(function(key, value) {
      this.filters[key] = value;
    }, 500),
    applyFilterMultiple: debounce(function(filters) {
      Object.assign(this.filters, filters);
    }, 500)
  }
};
