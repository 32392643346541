<template>
  <item-form class="p-4" width="400px" :save="save" :fields="fields" ref="item">
    <template #default="{form}">
      <s-field-validate label="Search User" rules="required">
        <search v-model="form.id" />
      </s-field-validate>
      <s-field-validate name="email" label="Email" rules="email">
        <s-textbox v-model="form.email" type="email" placeholder="Email" />
      </s-field-validate>
      <s-field-validate name="password" label="Password">
        <s-textbox v-model="form.password" placeholder="Password" />
      </s-field-validate>
    </template>
    <template #footer="{saving,saveItem}">
      <div>
        <s-button
          class="mr-2"
          type="submit"
          label="Change"
          color="primary"
          :loader="saving"
          @click.native="saveItem"
        />
        <s-button
          label="Reset"
          :loader="saving"
          class="reset-btn"
          @click.native="reset"
        />
      </div>
    </template>
  </item-form>
</template>
<script>
import reset from "@/graph/users/reset.gql";
export default {
  components: {
    ItemForm: require("@/components/ItemForm").default,
    Search: require("@/components/Search").default,
  },
  data() {
    return {
      fields: ["email", "password", { name: "id", value: {} }],
    };
  },
  methods: {
    get() {
      return { email: "", password: "", id: {} };
    },
    reset() {
      this.$refs.item.refresh();
    },
    save(id, data) {
      let finalData = { ...data };
      return this.$graphSparta(
        reset,
        {
          ...finalData,
          id: finalData?.id?.id,
        },
        true
      ).then((res) => {
        this.$refs.item.refresh();
        return res;
      });
    },
  },
};
</script>
<style lang="scss">
.search-dropdown {
  .multiselect__tags {
    background: var(--color--grey--lightest);
    .multiselect__input {
      background: var(--color--grey--lightest);
    }
    .multiselect__single {
      background: var(--color--grey--lightest);
      font-size: 13px;
      color: #000;
    }
  }
}
.multiselect__option--highlight {
  background-color: var(--color--primary) !important;
  &::after {
    background-color: var(--color--primary) !important;
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.reset-btn {
  background-color: #eceff1;
}
</style>
