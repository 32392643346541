<template>
  <div class="wh-auth">
    <!-- Containers -->
    <s-copy />
    <s-modal-container />
    <s-confirm />

    <!-- Loading -->
    <div v-if="loading" class="flex flex--column h-100 flex--fit flex--center">
      <div class="p-4" v-shilp-loader="true" />
      <div class="text-center">
        <img class="h-5" src="/img/logo-dark.png" alt="" />
      </div>
    </div>

    <!-- Hydrated -->
    <s-layout
      v-else
      class="wh-layout"
      top-size="50px"
      left-size="180px"
      :left-classlist="['br-lightest']"
      :top-classlist="['bb-lightest']"
      full-height
      push-top
    >
      <template #top>
        <div class="flex flex--middle">
          <s-breadcrumbs class="flex__fluid" />
          <div class="flex__fit pr-3">
            <portal-target name="header" slim />
          </div>
        </div>
      </template>
      <template #left>
        <web-nav />
      </template>

      <template #default>
        <router-view />
      </template>
    </s-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import hydrateQuery from "@/graph/hydrate.gql";
import { init as initGraph } from "@/plugins/graphql";
import { setToken, setParams } from "@/plugins/axios";

export default {
  data() {
    return {
      loading: true,
      campaignId: localStorage.getItem("wha-campaign"),
    };
  },

  components: {
    WebNav: require("@/components/WebNav").default,
  },

  async mounted() {
    /**
     * Check for token
     * Set Axios token in header before sending the hydration call
     */
    const token = localStorage.getItem("wha-token");
    if (!token) {
      this.$router.push({ name: "login" });
      return;
    }
    setToken(token);
    initGraph(token);

    this.startHydration(false);
  },

  computed: {
    ...mapGetters(["campaign", "hydrated"]),
  },

  methods: {
    ...mapActions(["hydrate", "selectCampaign"]),

    async startHydration(background) {
      if (!background) {
        this.loading = true;
      }

      try {
        await this.hydrate(
          this.$graph(hydrateQuery, {
            campaignId: Number(this.campaignId),
          }).catch((err) => {
            console.error(err);
            this.$router.push({ name: "logout" });
          })
        );

        //Select the default campaign
        await this.selectCampaign(this.campaignId);

        //Set Axios token in header before sending the hydration call
        setParams({ campaign_id: this.campaignId });

        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
        gtm("failed_login", {});

        this.$notify({
          type: "danger",
          title: "Failed to Login!",
          message:
            "Can not fetch user data. Please login again. If issue persist, kindly contact us.",
          duration: 10000,
        });
        this.$router.replace({ name: "logout" });
      }
    },
  },
};
</script>

<style lang="scss">
.wh-auth {
  height: 100%;
  overflow: auto;
}
</style>
