import { HYDRATING, HYDRATED, HYDRATION_FAILED } from "./mutation-types";

const state = {
  hydrating: false,
  hydrated: false,
  failed: null,
  metaData: null,
};

const getters = {
  hydrated: (state) => state.hydrated,
  hydrating: (state) => state.hydrating,

  metaData(state) {
    return state.metaData;
  },
};

const mutations = {
  [HYDRATING](state) {
    state.hydrating = true;
  },
  [HYDRATED](state, data) {
    state.hydrated = true;
    state.hydrating = false;

    const { metaData } = data;
    state.metaData = metaData;
  },
  [HYDRATION_FAILED](state) {
    state.failed = true;
    state.hydrating = false;
  },
};

const actions = {
  async hydrate({ commit, dispatch }, request) {
    commit(HYDRATING);

    const res = await request;

    const data = {
      campaigns: res.campaigns?.data,
      user: res.user,
      tags: res.tags?.data,
      subscription: res.user.subscription,
      funnels: res.funnels?.data,
    };

    try {
      await dispatch("setAccount", data);
      await dispatch("subscription/set", data.subscription);
      commit(HYDRATED, {
        metaData: res.metaData,
      });
      return res;
    } catch (err) {
      commit(HYDRATION_FAILED);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
