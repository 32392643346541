<template>
  <div class="flex__fluid">
    <vc-date-picker
      class="flex w-full"
      :value="value * 1000"
      @input="input($event)"
      :max-date="maximum"
      :min-date="minimum"
      :mode="mode"
      :masks="{ input: ['DD/MM/YYYY'] }"
      :popover="{
        visibility: 'click',
      }"
    >
      <template v-slot="{ inputValue, inputEvents, togglePopover }">
        <div class="calender-container bg-grey-lightest">
          <input
            class="field-block v-calendar__radius"
            :value="inputValue"
            v-on="inputEvents"
            :disabled="disabled"
          />
          <s-icon
            v-if="clickable"
            :size="20"
            class="p-2 text-grey pointer"
            name="vmdi-calendar-month"
            @click.native="!disabled ? togglePopover() : ''"
          />
        </div>
      </template>
    </vc-date-picker>
  </div>
</template>
<script>
import { format } from "date-fns";
export default {
  props: {
    value: [String, Number, Date],
    maxDate: { type: [String, Number], default: "" },
    minDate: { type: [String, Number], default: "" },
    maxAllow: {
      type: Boolean,
      default: false,
    },
    minAllow: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "date",
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: null,
      maximum: null,
      minimum: null,
    };
  },
  created() {
    if (this.maxDate && this.maxDate != "null" && this.maxAllow) {
      this.maximum = format(new Date(this.maxDate * 1000), "yyyy/MM/dd");
    }

    if (this.minDate && this.minDate != "null" && this.minAllow) {
      this.minimum = format(new Date(this.minDate * 1000), "yyyy/MM/dd");
    }
  },
  watch: {
    minDate: {
      deep: true,
      handler(date) {
        this.minimum = format(new Date(date * 1000), "yyyy/MM/dd");
      },
    },
    maxDate: {
      deep: true,
      handler(date) {
        this.maximum = format(new Date(date * 1000), "yyyy/MM/dd");
      },
    },
  },
  methods: {
    input(date) {
      // if (!date) return;

      this.$emit("input", this.toTimestamp(date));
    },
    toTimestamp(strDate) {
      if (!strDate) {
        return "";
      }
      let datum = Date.parse(strDate);
      return datum / 1000;
    },
  },
};
</script>
<style scoped>
.v-calendar__radius {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.calender-container {
  display: flex !important;
  align-items: center !important;
  border-radius: 3px;
}

.pointer {
  cursor: pointer;
}
</style>
