<template>
  <s-layout
    full-height
    class="list-layout"
    left-size="200px"
    :left-classlist="['br-lightest']"
  >
    <template v-if="$scopedSlots.left" #left>
      <slot name="left" />
    </template>

    <template #default>
      <portal to="header">
        <div :key="route" class="list g-2">
          <slot name="header-start" />
          <s-button
            v-if="addRoute"
            size="sm"
            icon="Plus"
            color="primary"
            :to="addRouteObject()"
            label="Add New"
          />
          <s-button
            v-if="onAdd"
            size="sm"
            icon="Plus"
            color="primary"
            @click.native="onAdd"
            label="Add New"
          />
          <slot name="header-end" />
        </div>
      </portal>
      <slot />
    </template>
  </s-layout>
</template>

<script>
export default {
  props: {
    addRoute: [String, Object],
    onAdd: Function
  },
  computed: {
    route() {
      return typeof this.addRoute === "string"
        ? this.addRoute
        : this.addRoute?.name;
    }
  },
  methods: {
    addRouteObject() {
      if (typeof this.addRoute === "string") {
        return { name: this.addRoute, params: { id: "+" } };
      } else {
        return { name: this.addRoute?.name, params: this.addRoute?.params };
      }
    }
  }
};
</script>
