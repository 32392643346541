import orderStatusList from "@/data/order-status";
import paymentStatusList from "@/data/payment-status";
import paymentModes from "@/data/payment-modes";
import {
  SELECT_CAMPAIGN,
  SET_CONTACT_FILTERS,
  SET_CONTACT_SEARCH_QUERY,
  ADD_INTERACTION,
  SET_DAILY_QUICK_REPLY_LEFT,
  SET_EDITED_MESSAGE_BEFORE_SEND,
  SET_PROFILE_REDIRECT,
  WAPI_LOADED,
  WEB_WHATSAPP_INFO
} from "./mutation-types";

const state = {
  activeCampaign: null,
  contactFilters: null,
  contactSearchQuery: null,
  interacted: [],
  dailyQuickReplyLeft: 20,
  editedMessageBeforeSend: null,
  //Should redirect to profile when contact change event is triggered.
  //This is required false while contact search is on.
  isProfileRedirect: true,
  isWapiLoaded: false,
  webWhatsAppInfo: null
};

const getters = {
  webLoginUrl: state => redirect => {
    const baseUrl = process.env.VUE_APP_ADMIN_URL;
    const token = window.localStorage.getItem("wh-token");
    let url = `${baseUrl}#/login?token=${token}`;
    if (redirect) {
      url += `&redirect=${redirect}`;
    }
    return url;
  },

  isContactSelected(state, getters, rootState) {
    return rootState.contact.profile ? true : false;
  },

  orderStatusDropdown() {
    return orderStatusList;
  },

  paymentStatusDropdown() {
    return paymentStatusList;
  },

  paymentModes(state, getters, rootState) {
    const prepaidModes = rootState.account.store?.paymentModes;
    const enabled = [];
    if (prepaidModes) {
      Object.keys(prepaidModes).forEach(key => {
        const mode = prepaidModes[key];
        if (mode.isConfigured && mode.isEnabled) {
          enabled.push({
            value: mode.id,
            label: mode.displayName || mode.name
          });
        }
      });
    }
    return [...paymentModes, ...enabled];
  },

  dailyQuickReplyLeft(state) {
    return state.dailyQuickReplyLeft;
  }
};

const mutations = {
  [SELECT_CAMPAIGN](state, id) {
    state.activeCampaign = id;
  },
  [SET_CONTACT_FILTERS](state, data) {
    state.contactFilters = data;
  },
  [SET_CONTACT_SEARCH_QUERY](state, value) {
    state.contactSearchQuery = value;
  },
  [ADD_INTERACTION](state, id) {
    state.interacted.push(id);
  },
  [SET_DAILY_QUICK_REPLY_LEFT](state, value) {
    state.dailyQuickReplyLeft = value;
  },
  [SET_EDITED_MESSAGE_BEFORE_SEND](state, value) {
    state.editedMessageBeforeSend = value;
  },
  [SET_PROFILE_REDIRECT](state, value) {
    state.isProfileRedirect = value;
  },
  [WAPI_LOADED](state) {
    state.isWapiLoaded = true;
  },
  [WEB_WHATSAPP_INFO](state, data) {
    state.webWhatsAppInfo = data;
  }
};

const actions = {
  selectCampaign({ state, commit, rootState }, id) {
    if (!rootState.account.campaigns) {
      return false;
    }
    if (!id) {
      id = rootState.account.campaigns[0].id;
    }
    commit(SELECT_CAMPAIGN, id);
    return id;
  },

  setContactFilters({ commit }, data) {
    commit(SET_CONTACT_FILTERS, data);
  },

  setContactSearchQuery({ commit }, data) {
    commit(SET_CONTACT_SEARCH_QUERY, data);
  },

  addInteraction({ state, commit }, id) {
    id = String(id);
    if (!state.interacted.includes(id)) {
      commit(ADD_INTERACTION, id);
    }
  },

  setDailyQuickReplyLeft({ state, commit }, value) {
    let left;
    if (value != null) {
      left = value;
    } else {
      left = state.dailyQuickReplyLeft - 1;
    }
    commit(SET_DAILY_QUICK_REPLY_LEFT, left);
  },

  setEditedMessageBeforeSend({ commit }, value) {
    commit(SET_EDITED_MESSAGE_BEFORE_SEND, value);
  },

  setProfileRedirect({ commit }, value) {
    commit(SET_PROFILE_REDIRECT, value);
  },

  wapiLoaded({ commit }) {
    commit(WAPI_LOADED);
  },

  setWebWhatsAppInfo({ commit }, data) {
    commit(WEB_WHATSAPP_INFO, data);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
