import Vue from "vue";
import VueRouter from "vue-router";
import privateRoutes from "./private";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/"
  },
  {
    path: "/",
    component: require("@/views/Hydrate").default,
    meta: {
      public: false
    },
    children: privateRoutes
  },
  {
    path: "/login",
    name: "login",
    component: require("@/views/public/Login").default,
    meta: {
      public: true
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: require("@/views/Logout").default,
    meta: {
      public: true
    }
  }
];

const router = new VueRouter({
  routes,
  mode: "hash"
});

router.beforeEach(async (from, to, next) => {
  if (from.meta.public === true) {
    next();
  } else {
    const token = localStorage.getItem("wha-token");
    if (token) {
      next();
    } else {
      next({ name: "logout" });
    }
  }
});

router.afterEach((to, from) => {
  if (!["login", "logout", "forgot-password"].includes(to.name)) {
    localStorage.setItem("wha-last-path", to.path);
  }
});

export default router;
