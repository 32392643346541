<template>
  <div class="login-container">
    <item-form
      class="login"
      :footer-class-list="['mt-4']"
      :save="login"
      :fields="fields"
      ref="item"
    >
      <template #default="{form}">
        <div>
          <img src="/img/logo-dark.png" width="260px" alt="vepaar" />
        </div>
        <s-field-validate
          rules="required|email"
          name="email"
          before-icon="vmdi-email"
        >
          <s-textbox v-model="form.email" type="text" />
        </s-field-validate>
        <s-field-validate
          rules="required"
          name="password"
          before-icon="vmdi-lock"
        >
          <s-textbox v-model="form.password" type="password" />
        </s-field-validate>
      </template>

      <template #footer="{saving}">
        <s-button
          :loader="saving"
          :disabled="saving"
          icon="vmdi-login-variant"
          align="right"
          color="primary"
          type="submit"
          fluid
          label="Login"
        />
      </template>
    </item-form>
  </div>
</template>

<script>
import login from "@/graph/login.gql";

export default {
  data() {
    return {
      fields: ["email", "password"],
    };
  },

  components: {
    ItemForm: require("@/components/ItemForm").default,
  },

  methods: {
    login(id, data) {
      return this.$graphPublic(login, {
        input: data,
      }).then((res) => {
        const token = res.login.token;
        const campaign = res.login.user.campaigns[0]?.id;
        this.afterLogin(token, campaign);
        return true;
      });
    },

    afterLogin(token, campaign) {
      if (token) {
        localStorage.setItem("wha-token", token);
      }
      if (campaign) {
        localStorage.setItem("wha-campaign", campaign);
      }
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
.login-container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    #304ffe,
    #6200ea 33%,
    #9008ab 66%,
    #c51162
  );
  .login {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}
.login {
  width: 300px;
}
</style>
