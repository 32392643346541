<template>
  <div class="web-nav px-2 h-100" :class="{ 'web-nav--collapsed': collapsed }">
    <div class="py-3 text-center" v-if="collapsed">
      <img src="/img/icon.png" width="32px" />
    </div>
    <div v-else class="p-3 text-center">
      <img src="/img/logo-light.png" width="140px" />
    </div>

    <s-button-group
      class="web-nav__items flex flex--column"
      stack
      size="lg"
      align="left"
      :shape="collapsed ? 'square' : null"
    >
      <s-button
        icon="Briefcase"
        :to="{ name: 'campaigns' }"
        label="Campaigns"
        v-tooltip="tooltip('Campaigns')"
      />

      <s-button
        icon="AccountGroup"
        :to="{ name: 'users' }"
        label="Users"
        v-tooltip="tooltip('Users')"
      />

      <s-button
        icon="CurrencyUsdCircle"
        :to="{ name: 'plans' }"
        label="Plans"
        v-tooltip="tooltip('Plans')"
      />

      <s-button
        icon="vmdi-cog"
        :to="{ name: 'setting' }"
        label="Setting"
        v-tooltip="tooltip('Setting')"
      />

      <s-button
        class="mt-auto"
        icon="LogoutVariant"
        @click.native="logout"
        label="Logout"
        v-tooltip="tooltip('Logout')"
      />
    </s-button-group>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      isWeb: process.env.VUE_APP_WEB == "true",
    };
  },
  computed: {
    ...mapState({
      collapsed: (state) => state.pref.isAdminNavCollapsed,
    }),
  },

  mounted() {
    if (this.isWeb) {
      window.Olvy?.init(window.OlvyConfig);
    }
  },

  methods: {
    ...mapActions({
      setPref: "pref/set",
    }),
    tooltip(content) {
      return {
        content: content,
        disabled: !this.collapsed,
        delay: { show: 500, hide: 0 },
        placement: "right",
      };
    },
    logout() {
      this.$router.push({
        name: "logout",
      });
    },
  },
};
</script>

<style lang="scss">
.web-nav {
  background-color: #111827;
  grid-column: 1 / 2;
  overflow: auto;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}
.web-nav--collapsed {
  .button__label {
    display: none;
  }
  .olvy-unread-indicator {
    position: absolute !important;
    bottom: 6px !important;
    right: 6px !important;
    top: auto !important;
  }
}
.web-nav__items {
  flex: 1 1 auto;
  .button {
    --button--color: rgba(255, 255, 255, 0.3);
    --button--hover-color: rgba(255, 255, 255, 1);
    flex: 0 0 auto;
  }
  .button--active,
  .button--exact-active {
    position: relative;
    --button--bg: rgba(255, 255, 255, 0.08);
    --button--hover-bg: rgba(255, 255, 255, 0.08);
    color: #fff;
  }
  .button--highlight {
    --button--color: #{--color("warning", "light")};
    --button--hover-color: #{--color("warning")};
  }
}
</style>
