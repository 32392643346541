import requests from "@/api/requests";

//sendRequest
//Handles the API requests for browser mode and extension mode
export default (name, payload) => {
  if (!requests[name]) {
    console.error(`Can not find an API: ${name}`);
    throw new Error();
  }
  return new Promise(resolve => {
    resolve(requests[name](payload));
  });
};
