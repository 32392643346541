<template>
  <div class="p-3" v-if="loading && !error">
    <div class="shimmer h-4 mt-1 radius-2" />
    <div class="shimmer h-4 mt-1 radius-2" />
    <div class="shimmer h-4 mt-1 radius-2" />
    <div class="shimmer h-4 mt-1 radius-2" />
    <div class="shimmer h-4 mt-1 radius-2" />
    <div class="shimmer h-4 mt-1 radius-2" />
  </div>
  <div class="p-3" v-else-if="users">
    <s-media class="bg-grey-lightest radius-4" ratio="1x1" :value="getImage" />
    <h4 class="mt-2">{{ users.name }}</h4>
    <p class="mt-1 text-grey">
      {{ users.mobileNumber.numberWithCallingCode }}
    </p>
    <sub-nav class="mt-3">
      <slot />
    </sub-nav>
  </div>
</template>

<script>
export default {
  props: {
    request: Function
  },
  components: {
    SubNav: require("@/components/SubNav").default
  },
  data() {
    return {
      loading: false,
      error: null,
      users: null
    };
  },
  created() {
    this.get();
  },
  computed: {
    getImage() {
      return (
        (this.users.campaigns &&
          this.users.campaigns[0]["profilePicture"] &&
          this.users.campaigns[0]["profilePicture"]["url"]) ||
        "/img/avatar.svg"
      );
    }
  },
  methods: {
    async get() {
      this.error = null;
      this.loading = true;
      try {
        this.users = await this.request();
      } catch (err) {
        this.error = err;
      }
      this.loading = false;
    }
  }
};
</script>
