import axios from "@/plugins/axios";

export const list = () => {
  return axios.get("V2/campaign").then(res => {
    return {
      items: res.data.items,
      count: res.data.meta.count
    };
  });
};

export default {
  list
};
