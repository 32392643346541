<template>
  <sp-list
    endpoint="SPARTA_GQL:spartaCampaign"
    :attrs="columns"
    :actions="['search', 'settings', 'filters', 'refresh']"
    :filters="cleanedFilters"
    :perPage="100"
    class="h-100"
  >
    <template #filters>
      <list-filters @reset="cleanFilter()">
        <s-field label="Plan" class="mb-3">
          <s-select :request="plans" v-model="userFilter.plan" />
        </s-field>
        <s-field name="From" label="From login date">
          <v-date-picker :clickable="true" v-model="userFilter.fromLoginDate" />
        </s-field>
        <s-field name="To" label="To login date">
          <v-date-picker
            :clickable="true"
            :minAllow="true"
            v-model="userFilter.toLoginDate"
            :minDate="userFilter.fromLoginDate"
          />
        </s-field>
        <s-field label="Custom Domain" class="mb-3">
          <s-select
            :options="customDomainOption"
            placeholder="Select"
            :value="String(campaignFilter.customDomainExists)"
            @input="campaignFilter.customDomainExists = eval($event)"
          />
        </s-field>
        <s-field label="User Verification" class="mb-3">
          <s-select
            :value="String(userFilter.isUserVerified)"
            @input="userFilter.isUserVerified = eval($event)"
            :options="userVerificationOption"
            placeholder="Select"
          />
        </s-field>
        <s-field label="Quick Reply Count" class="mb-3">
          <vue-slider
            :min="0"
            :max="200"
            :value="[filters.quickReply.ge, filters.quickReply.le]"
            lazy
            @change="sliderRangeChange($event, 'quickReply')"
          />
        </s-field>
        <s-field label="Quick Reply Sent" class="mb-3">
          <vue-slider
            :min="0"
            :max="200"
            :value="[filters.quickReplySent.ge, filters.quickReplySent.le]"
            lazy
            @change="sliderRangeChange($event, 'quickReplySent')"
          />
        </s-field>
        <s-field label="AVG QR Sent" class="mb-3">
          <vue-slider
            :min="0"
            :max="200"
            :value="[
              filters.quickReplySentDaily.ge,
              filters.quickReplySentDaily.le,
            ]"
            lazy
            @change="sliderRangeChange($event, 'quickReplySentDaily')"
          />
        </s-field>
        <s-field label="Tags" class="mb-3">
          <vue-slider
            :min="0"
            :max="200"
            :value="[filters.tag.ge, filters.tag.le]"
            lazy
            @change="sliderRangeChange($event, 'tag')"
          />
        </s-field>
        <s-field label="Polls" class="mb-3">
          <vue-slider
            :min="0"
            :max="200"
            :value="[filters.poll.ge, filters.poll.le]"
            lazy
            @change="sliderRangeChange($event, 'poll')"
          />
        </s-field>
        <s-field label="Products">
          <vue-slider
            :min="0"
            :max="200"
            :value="[filters.product.ge, filters.product.le]"
            lazy
            @change="sliderRangeChange($event, 'product')"
          />
        </s-field>
      </list-filters>
    </template>
    <sp-list-table sticky-header class="pt-0 sparta-campaign-list">
      <template #users="{item}">
        <div v-if="item.users" class="list list--stack g-1">
          <div
            v-for="(user, index) in item.users"
            :key="index"
            class="list list--stack g-1"
          >
            <div class="flex">
              <router-link
                :class="
                  user.verifiedAt
                    ? 'text-green-darker flex__fit'
                    : 'text-warning-darker flex__fit'
                "
                :to="{
                  name: 'user',
                  params: {
                    id: user.id,
                  },
                }"
              >
                {{ user.name }}
              </router-link>
              <s-button
                v-if="user.subscription.plan.name"
                class="text-capitalize flex__fit ml-2"
                badge
                size="xs"
                shape="pill"
                theme="muted"
                :label="user.subscription.plan.name"
                color="info"
              />
            </div>
            <a class="text-grey" :href="`mailto:${user.email}`">
              {{ user.email }}
            </a>
            <div class="text-grey text-xs">
              <span>Register </span>
              <datetime
                tag="span"
                :suffix="true"
                :value="user.registrationAt"
              />
            </div>
            <div class="text-grey text-xs" v-if="user.lastLoginAt">
              <span>Active </span>
              <datetime tag="span" :suffix="true" :value="user.lastLoginAt" />
            </div>
            <!-- <div class="text-grey text-xs">
              {{ user.subscription.plan.name }}
            </div> -->
          </div>
        </div>
      </template>
      <template #campaignId="{item}">
        <s-button
          v-if="item.campaign"
          @click.native="$shilp.copy(item.campaign.id)"
        >
          {{ item.campaign.id }}
        </s-button>
      </template>
      <template #campaignMobile="{item}">
        <div v-if="item.campaign">
          <p v-for="(mob, index) in item.campaign.mobiles" :key="index">
            <a
              v-if="mob.mobileNumber.numberWithCallingCode"
              class="text-grey"
              :href="`https://wa.me/${mob.mobileNumber.numberWithCallingCode}`"
            >
              {{ mob.mobileNumber.internationalFormat }}
            </a>
          </p>
        </div>
      </template>
      <template #customDomainExists="{item}">
        <s-button
          v-if="item.campaign"
          :color="item.campaign.customDomainExists ? 'success' : 'grey'"
          theme="muted"
          size="sm"
          :icon="item.campaign.customDomainExists ? 'CheckBold' : 'Close'"
          shape="circle"
        />
      </template>
      <template #bizPage="{item}">
        <s-button
          v-if="item.campaign && item.campaign.url"
          color="grey"
          theme="muted"
          size="sm"
          :href="item.campaign.url"
          target="_blank"
          icon="OpenInNew"
          shape="circle"
        />
      </template>
    </sp-list-table>
  </sp-list>
</template>

<script>
import list from "@/mixins/list";
import spartaFilter from "@/mixins/sparta-filter";
import { mapGetters } from "vuex";
import cleanDeep from "clean-deep";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import { buildCleaner } from "lodash-clean";
const clean = buildCleaner({
  isNull: () => undefined,
});

export default {
  mixins: [list, spartaFilter],
  data() {
    return {
      clean,
      buildCleaner,
      cleanDeep,
      filters: {
        usageFilter: { quickReply: null },
        quickReply: { ge: null, le: null },
        quickReplySent: { ge: null, le: null },
        quickReplySentDaily: { ge: null, le: null },
        tag: { ge: null, le: null },
        poll: { ge: null, le: null },
        product: { ge: null, le: null },
      },
      userFilter: {
        plan: null,
        isUserVerified: null,
        fromLoginDate: null,
        toLoginDate: null,
      },
      campaignFilter: {
        customDomainExists: null,
      },
      customDomainOption: [
        {
          label: "Custom",
          value: "true",
        },
        {
          label: "whats.biz",
          value: "false",
        },
      ],
      userVerificationOption: [
        {
          label: "Verified",
          value: "true",
        },
        {
          label: "Unverified",
          value: "false",
        },
      ],
    };
  },
  components: {
    VueSlider,
    Datetime: require("@/components/Datetime").default,
    ListFilters: require("@/components/ListFilters").default,
    VDatePicker: require("@/components/VDatePicker").default,
  },

  computed: {
    ...mapGetters(["campaign"]),
    cleanedFilters() {
      return clean({
        ...this.filters,
        userFilter: this.userFilter,
        campaignFilter: this.campaignFilter,
      });
    },
    params() {
      return { campaignId: this.campaign.id };
    },
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "users", fix: true },
        { name: "campaignId", fix: true },
        { name: "campaignMobile", fix: true },
        { name: "user", label: "Users", fix: true, sortable: true },
        {
          name: "quickReplyAnalytics",
          label: "Quick Reply",
          fix: true,
          attrs: [
            {
              name: "quickReply",
              label: "Count",
              fix: true,
              sortable: true,
            },
            {
              name: "quickReplySent",
              label: "Sent",
              fix: true,
              sortable: true,
            },
            {
              name: "quickReplySentDaily",
              label: "Usage",
              fix: true,
              sortable: true,
            },
          ],
        },

        { name: "tag", label: "Tags", fix: true, sortable: true },
        {
          name: "tagUsage",
          fix: true,
          attrs: [
            {
              name: "contactWithTag",
              label: "Contacts",
              fix: true,
              sortable: true,
              key: "contact_with_tag",
            },
            { name: "mediaWithTag", label: "Media", fix: true, sortable: true },
            {
              name: "messageWithTag",
              label: "Messages",
              fix: true,
              sortable: true,
            },
          ],
        },
        { name: "funnel", label: "Funnels", fix: true, sortable: true },
        {
          name: "whatsApp",
          label: "WhatsApp",
          fix: true,
          attrs: [
            {
              name: "contact",
              label: "Contacts",
              fix: true,
              sortable: true,
            },
            { name: "group", label: "Groups", fix: true, sortable: true },
            {
              name: "broadcast",
              label: "Broadcast",
              fix: true,
              sortable: true,
            },
          ],
        },

        // @harsh Bhai check from here
        // {
        //   name: "store",
        //   fix: true,
        //   attrs: [
        //     { name: "product", label: "Products", fix: true, sortable: true },
        //     { name: "order", label: "Orders", fix: true, sortable: true },
        //     { name: "badges", fix: true, sortable: true },
        //     {
        //       name: "productWithBadges",
        //       label: "Badge Usage",
        //       fix: true,
        //       sortable: true,
        //     },
        //   ],
        // },
        { name: "product", label: "Products", fix: true, sortable: true },
        { name: "order", label: "Orders", fix: true, sortable: true },
        { name: "productTag", fix: true, sortable: true },
        {
          name: "productWithTag",
          label: "Product With tag",
          fix: true,
          sortable: true,
        },

        { name: "poll", label: "Polls", fix: true, sortable: true },
        { name: "pollVote", label: "Votes", fix: true, sortable: true },
        { name: "customDomainExists", label: "Custom Domain", fix: true },
        { name: "bizPage", fix: true },
      ];
    },
  },
  methods: {
    eval: (value) => eval(value),
    sliderRangeChange(value, type) {
      this.filters[type]["ge"] = value[0];
      this.filters[type]["le"] = value[1];
    },
    cleanFilter() {
      this.userFilter = {
        plan: null,
        isUserVerified: null,
        fromLoginDate: null,
        toLoginDate: null,
      };
      this.campaignFilter = {
        customDomainExists: null,
      };
      this.resetFilters();
    },
  },
};
</script>

<style lang="scss">
.sparta-campaign-list {
  .sp-table__table {
    --sp-table--hover-color: #fffbeb;
    td,
    th {
      border-left-width: 1px;
      border-right-style: 1px;
    }
    tr:nth-child(odd) {
      background-color: #f9fafb;
      &:hover {
        background-color: var(--sp-table--hover-color);
      }
    }
  }
}
</style>
