<template>
  <list-layout>
    <sp-list
      endpoint="SPARTA_GQL:spartaSubscription"
      :attrs="columns"
      :params="params"
      :actions="['search', 'settings', 'refresh']"
    >
      <sp-list-table sticky-header class="pt-0" :reorder="true">
        <template #name="{item}">
          <p>{{ item.name.split(" ")[0] }}</p>
        </template>
        <template #duration="{item}">
          <p class="text-capitalize">{{ item.duration }}</p>
        </template>
      </sp-list-table>
    </sp-list>
  </list-layout>
</template>

<script>
import list from "@/mixins/list";
import spartaFilter from "@/mixins/sparta-filter";
import { pickBy } from "lodash-es";
import { mapGetters } from "vuex";
export default {
  mixins: [list, spartaFilter],
  data() {
    return {
      pickBy,
      filters: {
        name: null,
        duration: null,
      },
    };
  },
  computed: {
    ...mapGetters(["campaign"]),

    params() {
      return { campaignId: this.campaign.id };
    },
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "name", sortable: true },
        { name: "description" },
        { name: "duration", fix: true },
        // { name: "_drag", label: "", fix: true }
      ];
    },
  },
};
</script>
