var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('list-layout',[(_vm.user.campaigns)?_c('sp-list',{attrs:{"data":_vm.user.campaigns,"attrs":_vm.columns,"actions":['refresh'],"filters":_vm.filters}},[_c('sp-list-table',{staticClass:"pt-0",attrs:{"sticky-header":"","reorder":true},scopedSlots:_vm._u([{key:"country",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.country && item.country.name))])]}},{key:"profilePicture",fn:function(ref){
var item = ref.item;
return [_c('s-media',{attrs:{"value":item.profilePicture && item.profilePicture.url,"width":"40px","height":"40px"}})]}},{key:"subdomain",fn:function(ref){
var item = ref.item;
return [(item.subdomain)?_c('s-button',{attrs:{"label":item.subdomain,"color":"info","href":item.subdomainUrl,"target":"_blank","size":"sm"}}):_vm._e()]}},{key:"users",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.users.length))])]}},{key:"replies",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.replies.length))])]}},{key:"funnels",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.funnels.length))])]}},{key:"contacts",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.contacts.length))])]}}],null,false,2207937591)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }