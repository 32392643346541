<template>
  <div class="dashboard p-4 bg-grey-lightest h-100">
    <div class="grid grid--4 g-3 mb-3">
      <emoji-card inline :title="user.name" desc="Name" />
      <emoji-card inline :title="user.email" desc="Email" />
    </div>
    <div class="grid grid--4 g-3 mb-3">
      <emoji-card
        inline
        :title="user.campaignCount || 0"
        desc="Campaign Count"
      />
      <emoji-card inline :title="getMobileNumber" desc="Mobile Number" />
    </div>
    <div class="grid grid--4 g-3 mb-3">
      <emoji-card inline :title="'' + user.isSpartan" desc="isSpartan" />
      <emoji-card inline desc="Last Login At">
        <template #title>
          <datetime :suffix="true" :value="user.lastLoginAt" />
        </template>
      </emoji-card>
    </div>
    <div class="grid grid--4 g-3 mb-3">
      <emoji-card inline desc="Registration At">
        <template #title>
          <datetime :suffix="true" :value="user.registrationAt" />
        </template>
      </emoji-card>
    </div>
  </div>
  <!-- <div class="dashboard p-4">
    <s-table :size="4" border="y">
      <tr>
        <td>Name</td>
        <td>{{ user.name }}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>{{ user.email }}</td>
      </tr>
      <tr>
        <td>Campaign Count</td>
        <td>{{ user.campaignCount || 0 }}</td>
      </tr>
      <tr>
        <td>Mobile Number</td>
        <td>
          {{ getMobileNumber }}
        </td>
      </tr>
      <tr>
        <td>isSpartan</td>
        <td>
          {{ user.isSpartan ? "✅" : "❌" }}
        </td>
      </tr>
      <tr>
        <td>Last Login At</td>
        <td>
          <datetime :suffix="true" :value="user.lastLoginAt" />
        </td>
      </tr>
      <tr>
        <td>Registration At</td>
        <td>
          <datetime :suffix="true" :value="user.registrationAt" />
        </td>
      </tr>
    </s-table>
  </div> -->
</template>

<script>
export default {
  props: {
    user: Object,
  },
  components: {
    EmojiCard: require("@/components/EmojiCard").default,
    Datetime: require("@/components/Datetime").default,
  },
  data() {
    return {};
  },
  computed: {
    getMobileNumber() {
      return this.user.mobileNumber?.numberWithCallingCode;
    },
  },
};
</script>
