import collection from "@/graph/subscription/collection.gql";
import duration from "@/graph/duration.gql";

export default {
  methods: {
    plans() {
      return this.$graphSparta(
        collection,
        {
          page: 1
        },
        true
      ).then(res => {
        return res.subscriptionPlans.data.map(s => {
          return { value: s.id, label: s.name };
        });
      });
    },
    durations() {
      return this.$graph(duration, "").then(res => res.duration);
    }
  }
};
