import request from "@/utils/request";
import graph from "@/utils/graph";
import { graphSparta } from "@/plugins/graphql";
import collection from "../graph/collection";

export default {
  requestHandler(data) {
    const { endpoint, filters, params, search, pagination, sort } = data;
    const endpointCheck = endpoint.split(":");
    //GRAPHQL
    if (endpointCheck[0] == "GQL" || endpointCheck[0] == "SPARTA_GQL") {
      const gqlQuery = endpointCheck[1];
      const vars = {
        ...params,
        filters,
        search,
        perPage: pagination.perPage,
        page: pagination.page
      };

      if (sort && sort.by) {
        vars.sort = sort;
      }

      let graphInstance;
      if (endpointCheck[0] == "SPARTA_GQL") {
        graphInstance = graphSparta(collection[gqlQuery])(vars);
      } else {
        graphInstance = graph(collection[gqlQuery], vars);
      }
      return graphInstance.then(res => {
        //The collection has only key
        //So we can get it from first index.
        const keys = Object.keys(res);
        const queryRes = res[keys[0]];
        return {
          items: queryRes.data,
          count: queryRes.pagination.total,
          //Original Response, usefull if we want to display additional data send via API
          //GraphQL can request additional data so can make use of them.
          res: res
        };
      });
    }
    //RESET TABLE
    else {
      var newParams = {
        ...params,
        page: pagination.page
      };

      if (pagination.perPage != -1) {
        newParams.limit = pagination.perPage;
      }

      //TODO: Common search field should have `search` as key
      if (search) {
        newParams[params.searchKey || "search"] = search;
      } else {
        newParams[params.searchKey || "search"] = undefined;
      }
      return request(endpoint, { params: newParams });
    }
  }
};
