<template>
  <div
    class="search-dropdown"
    :class="{
      'loader loader--dark': isLoading,
    }"
  >
    <multiselect
      v-model="selectedValues"
      id="ajax"
      label="email"
      track-by="email"
      placeholder="Type to search"
      open-direction="bottom"
      :options="collection"
      :multiple="false"
      :searchable="true"
      :internal-search="false"
      :clear-on-select="false"
      :options-limit="300"
      :limit="3"
      :limit-text="limitText"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="false"
      @search-change="asyncFind"
    >
      <span slot="noResult">
        Oops! No elements found. Consider changing the search query.
      </span>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import userCollection from "@/graph/users/collection.gql";

export default {
  props: {
    value: Object,
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedValues: {},
      collection: [],
      isLoading: false,
    };
  },
  watch: {
    selectedValues: {
      deep: true,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
    value: {
      deep: true,
      handler(newValue) {
        this.selectedValues = newValue;
      },
    },
  },
  methods: {
    limitText(count) {
      return `and ${count} other items`;
    },
    asyncFind(query) {
      this.isLoading = true;
      let searchString = query;
      if (!query) {
        this.isLoading = false;
        return;
      }
      return this.$graphSparta(
        userCollection,
        {
          page: -1,
          search: searchString,
        },
        true
      )
        .then((res) => {
          this.collection = res?.users?.data || [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss"></style>
