import Vue from "vue";
import "./vee-validate";
require("./shilp-vue");

//  calender
import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: 'vc',
  popoverVisibility: 'focus' // Use <vc-calendar /> instead of <v-calendar />
});


/**
 * Popover
 */
import vTooltip from "v-tooltip";
Vue.use(vTooltip, {
  defaultBoundariesElement: document.body
});

/**
 * Portal
 */
import PortalVue from "portal-vue";
Vue.use(PortalVue);
