<template>
  <list-layout>
    <sp-list
      endpoint="SPARTA_GQL:spartaUsers"
      :attrs="columns"
      :params="params"
      :actions="['search', 'settings', 'filters', 'refresh']"
      :filters="pickBy(filters)"
    >
      <template #filters>
        <s-field label="Plan" class="mb-3">
          <s-select :request="plans" v-model="filters.plan" />
        </s-field>
        <s-field label="Last active">
          <s-select :request="durations" v-model="filters.lastLoginAt" />
        </s-field>
      </template>
      <sp-list-table
        sticky-header
        class="pt-0"
        :reorder="true"
        @rowClick="open('user', $event.id)"
      >
        <template #mobileNumber="{item}">
          <p>{{ item.mobileNumber.internationalFormat }}</p>
        </template>
        <template #subscription="{item}">
          <p>{{ item.subscription.plan.name }}</p>
        </template>
      </sp-list-table>
    </sp-list>
  </list-layout>
</template>

<script>
import list from "@/mixins/list";
import spartaFilter from "@/mixins/sparta-filter";
import { pickBy } from "lodash-es";
import { mapGetters } from "vuex";

export default {
  mixins: [list, spartaFilter],
  data() {
    return {
      pickBy,
      filters: {
        plan: null,
        lastLoginAt: null,
      },
    };
  },
  computed: {
    ...mapGetters(["campaign"]),

    params() {
      return { campaignId: this.campaign.id };
    },
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "name", sortable: true },
        { name: "email" },
        { name: "mobileNumber", fix: true },
        { name: "subscription", fix: true },
        // { name: "_drag", label: "", fix: true }
      ];
    },
  },
};
</script>
