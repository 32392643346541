<template>
  <s-layout full-height left-size="200px" :left-classlist="['br-lightest']">
    <template #left>
      <nav-users :request="get">
        <s-nav-item
          class="is-root"
          label="Profile"
          icon="CardAccountDetailsOutline"
          :to="{ name: 'user-profile' }"
        />
        <s-nav-item
          label="Campaigns"
          icon="Briefcase"
          :to="{ name: 'user-campaign' }"
        />
        <s-nav-item
          label="Subscription"
          icon="CurrencyUsdCircle"
          :to="{ name: 'user-subscription' }"
        />
      </nav-users>
    </template>
    <template #default>
      <router-view :user="user" />
    </template>
  </s-layout>
</template>

<script>
import { mapGetters } from "vuex";
import get from "@/graph/users/get.gql";

export default {
  props: {
    id: [Number, String]
  },
  data() {
    return {
      user: {}
    };
  },
  components: {
    NavUsers: require("./_Nav").default
  },
  computed: {
    ...mapGetters(["campaign"])
  },
  methods: {
    get() {
      return this.$graphSparta(
        get,
        {
          id: this.id
        },
        true
      ).then(res => {
        this.user = res.user;
        return res.user;
      });
    }
  }
};
</script>
