export const HYDRATING = "⏳ Hydrating";
export const WAPI_LOADED = "✨ WAPI Loaded";
export const WEB_WHATSAPP_INFO = "ℹ️ Web WhatsApp Info";
export const CONTACT_LOADING = "⏳ Contact Loading";
export const HYDRATED = "⌛️ Hydrated";
export const HYDRATION_FAILED = "🔴 Hydration Failed";
export const REHYDRATE = "💦 Rehydrate";
export const SET_ACCOUNT = "😎 Set Account";
export const SET_PLATFORM_USER = "😎 Set Platform User";
export const SET_USER = "😎 Set User";
export const SELECT_CAMPAIGN = "✅ Campaign Selected";
export const SET_PROFILE = "🧑‍💻 Setting Contact Profile";
export const SET_PROFILE_PIC = "🧑‍💻 Setting Contact Profile Picture";
export const UPDATE_ATTR = "🖌 Updating User Attribute";
export const RESET_UNSAVED = "🧹 Reset Unsaved Changes";
export const MSG_TO_SAVE = "💬 Message To Save";
export const UPDATE_MSG_TO_SAVE = "💬 Update Message To Save";
export const SET_CONTACT_FILTERS = "🗂 Set Contact Filters";
export const SET_CONTACT_SEARCH_QUERY = "🗂 Set Contact Query";
export const CART_ADD = "🛒 Adding to Cart";
export const CART_UPDATE_QTY = "🔢 Updating Cart Item Qty";
export const CART_REMOVE = "🙅‍♂️ Removing Cart Item";
export const CART_EMPTY = "🧹 Emptying Cart";
export const CART_UPDATE_FIELD = "🖌 Updating Cart Field";
export const NOTIFICATION_ADD = "🔔 Adding Notification";
export const NOTIFICATION_READ = "✅ Notification Read";
export const NOTIFICATION_READ_ALL = "✅✅ Notification Read";
export const WAIT_FOR_ALL_CHAT = "⏲ Waiting for all chat messages";
export const WAIT_FOR_MESSAGE = "⏲ Waiting for media";
export const SET_ALL_CHAT = "💬 Setting all chat";
export const ADD_INTERACTION = "🤙 Adding an interaction";
export const RESET_STATE = "🧹 Resetting State";
export const SET_DAILY_QUICK_REPLY_LEFT = "💬 Set Daily Quick Reply Left";
export const PIN_MESSAGE = "📌 Pin Message";
export const UNPIN_MESSAGE = "📌 UnPin Message";
export const SET_PROFILE_REDIRECT = "⤵️ Setting Profile Redirect";
export const SET_PREF = "🛠 Setting Preference";
export const SET_EDITED_MESSAGE_BEFORE_SEND =
  "🖋 Setting Edited Message Before Sending";
export const SET_SUBSCRIPTION = "🤑 Setting Subscription";
