<template>
  <span class="iconify" :data-icon="name" data-inline="false"></span>
</template>
<script>
export default {
  props: {
    name: String
  }
};
</script>
