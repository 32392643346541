import { SET_ACCOUNT, SET_USER } from "./mutation-types";

const state = {
  user: null,
  campaigns: null
};

const getters = {
  defaultCallingCode(state, getters) {
    return getters.campaign?.country?.callingCode;
  },

  campaign(state, getters, rootState) {
    if (!rootState.activeCampaign) return null;
    return state.campaigns.find(
      campaign => rootState.activeCampaign == campaign.id
    );
  },

  mobileOptions(state, getters) {
    const mobiles = getters.campaign?.mobiles || [];
    return mobiles.map(item => {
      return {
        label: item.mobileNumber?.internationalFormat || item.mobile,
        value: String(item.mobile)
      };
    });
  },

  isLoggedIn(state) {
    return state.user ? true : false;
  },

  biz(state, getters, rootState) {
    if (!rootState.activeCampaign) return null;
    return state.campaigns.find(
      campaign => rootState.activeCampaign == campaign.id
    );
  },

  user(state) {
    return state.user;
  },

  isSpartan(state) {
    return state.user?.isSpartan;
  }
};

const mutations = {
  [SET_ACCOUNT](state, data) {
    state.user = data.user;
    state.campaigns = data.campaigns;
  },

  [SET_USER](state, data) {
    state.user = data;
  }
};

const actions = {
  async setAccount({ commit, dispatch }, data) {
    commit(SET_ACCOUNT, data);
  },

  setUser({ commit }, data) {
    commit(SET_USER, data);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
